import PhotoSwipe from "photoswipe/dist/photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

$(function(){
    $('.js-photoswipe-gallery').each(function(i, el){
        var dataID = $(el).data('id');
        var items = JSON.parse(document.getElementById(dataID).textContent);

        $(el).find('a').click(function(e){
            e.preventDefault();
            var options = {
                index: 0,
                history: false,
                bgOpacity: 0.9,
            };
            var gallery = new PhotoSwipe($('.pswp')[0], PhotoSwipeUI_Default, items, options);
            gallery.init();
        });
    });
});
